import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { DocumentDuplicateIcon } from '@heroicons/react/24/solid';
const fetch = require('node-fetch');

// Avatar image URL
const assistantAvatar = "/mjst-pfp.png"; 

function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
}

function formatText(text) {
    return text
        .replace(/\n/g, '<br />')
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\【(.*?)\】/g, '');
}

export default function Chat() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false); 
    const [copiedIndex, setCopiedIndex] = useState(null); 
    const [autoScroll, setAutoScroll] = useState(true); 
    const messagesEndRef = useRef(null); 
    const containerRef = useRef(null);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = async () => {
        if (input.trim() === "") return;

        const newMessage = { role: 'user', content: input };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput(''); 
        setLoading(true);
        scrollToBottom(); // Immediately scroll after sending a message

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ messages: [...messages, newMessage] }),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let done = false;
            let buffer = '';

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                buffer += decoder.decode(value, { stream: true });

                let boundary = buffer.indexOf('\n');
                while (boundary !== -1) {
                    const chunk = buffer.slice(0, boundary);
                    buffer = buffer.slice(boundary + 1);

                    if (chunk.trim()) {
                        const { role, content } = JSON.parse(chunk);

                        setMessages(prevMessages => {
                            const newMessages = [...prevMessages];
                            const lastMessage = newMessages[newMessages.length - 1];
                            if (lastMessage && lastMessage.role === 'assistant') {
                                newMessages[newMessages.length - 1].content = content;
                            } else {
                                newMessages.push({ role, content });
                            }
                            return newMessages;
                        });

                        setLoading(false);
                        scrollToBottom(); // Ensure scrolling continues as messages load
                    }

                    boundary = buffer.indexOf('\n');
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: "Error processing your request." }]);
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const copyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000);
        });
    };

    return (
        <div>
            <div className="h-96 max-h-96 flex flex-col bg-blue-50 text-black relative rounded-lg shadow-md">
                <div className="flex justify-between items-center h-16 bg-blue-200 p-4 rounded-t-lg">
                    <h2 className="text-2xl font-bold text-blue-800">MJST - Le ChatGPT Gratuit</h2>
                </div>

                <div className="flex-1 p-4 overflow-auto" ref={containerRef}>
                    {messages.length > 0 ? (
                        messages.map((msg, index) => (
                            <div key={index} className={`flex items-start mb-4 ${msg.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'}`}>
                                {msg.role === 'assistant' && (
                                    <img src={assistantAvatar} alt="Monsieur Je Sais Tout" className="h-10 w-10 rounded-full mr-4" />
                                )}
                                <div className={`p-3 rounded-lg ${msg.role === 'assistant' ? 'bg-blue-100 text-blue-900' : 'bg-blue-500 text-white'} max-w-full shadow`}>
                                    <span dangerouslySetInnerHTML={{ __html: formatText(msg.content) }} />
                                    {msg.role === 'assistant' && (
                                        <div className="flex items-center mt-2 text-sm text-blue-700 hover:text-blue-900">
                                            <button onClick={() => copyToClipboard(msg.content, index)}>
                                                <DocumentDuplicateIcon className="h-4 w-4 inline mr-1" />
                                                {copiedIndex === index ? "Copié!" : "Copier"}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-blue-800">Salut et bienvenue ! Pose-moi ta question, je suis prêt à te surprendre !</p>
                    )}
                    {loading && (
                        <div className="flex items-center mb-4">
                            <img src={assistantAvatar} alt="Avatar" className="h-10 w-10 rounded-full mr-4" />
                            <div className="p-3 bg-blue-100 text-blue-900 rounded-lg max-w-xs shadow">...</div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>

                <div className="p-4 bg-blue-200 rounded-b-lg">
                    <div className="flex items-center">
                        <textarea
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                            className="flex-1 bg-white border border-blue-300 text-black px-4 py-2 rounded mr-2"
                            placeholder="Tape ta demande ou ta réflexion ici..."
                            rows={2}
                        />
                        <button onClick={sendMessage} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
                            Envoyer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
