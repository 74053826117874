import React from 'react';

export default function ConditionsUtilisation() {
    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Conditions d'Utilisation</h1>
            
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">1. Acceptation des Conditions</h2>
            <p className="text-gray-700 mb-4">
                En utilisant l'application "Monsieur Je Sais Tout," vous acceptez les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce service.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">2. Description du Service</h2>
            <p className="text-gray-700 mb-4">
                "Monsieur Je Sais Tout" est un chatbot basé sur l'intelligence artificielle, conçu pour fournir des réponses et du contenu génératif sur divers sujets. Le service est destiné à fournir des informations à titre indicatif uniquement et ne constitue pas un avis professionnel.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">3. Responsabilité et Limites de l'IA</h2>
            <p className="text-gray-700 mb-4">
                Le contenu généré par l'application est le résultat d'un processus automatisé et peut contenir des erreurs, des inexactitudes ou des informations obsolètes. L'IA peut produire des réponses "hallucinées," c’est-à-dire des affirmations incorrectes ou fictives. Nous ne garantissons pas l'exactitude ou la fiabilité des réponses fournies. L'utilisateur est invité à vérifier les informations auprès de sources fiables et à ne pas se baser uniquement sur l'IA pour prendre des décisions importantes.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">4. Utilisation Acceptable</h2>
            <p className="text-gray-700 mb-4">
                - Vous vous engagez à utiliser l'application de manière responsable et à ne pas soumettre de contenu offensant, illégal, ou qui enfreindrait les droits d'autrui.
                <br />
                - Vous ne devez pas utiliser l'application à des fins de spamming, de phishing, ou toute autre activité frauduleuse ou nuisible.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">5. Propriété Intellectuelle</h2>
            <p className="text-gray-700 mb-4">
                Tous les droits de propriété intellectuelle liés à l'application, y compris les textes, images, graphismes, logos, et tout autre contenu, sont la propriété de leurs détenteurs respectifs. Toute utilisation non autorisée de ces éléments est strictement interdite.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">6. Collecte et Utilisation des Données</h2>
            <p className="text-gray-700 mb-4">
                En utilisant l'application, certaines données personnelles peuvent être collectées, telles que vos interactions et requêtes. Nous nous engageons à traiter vos données conformément à notre Politique de Confidentialité. Aucune donnée ne sera partagée avec des tiers sans votre consentement, sauf si requis par la loi.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">7. Limitation de Responsabilité</h2>
            <p className="text-gray-700 mb-4">
                L'utilisation de l'application se fait à vos propres risques. "Monsieur Je Sais Tout" ne peut être tenu responsable des dommages directs, indirects, accidentels ou consécutifs résultant de l'utilisation ou de l'incapacité d'utiliser le service, y compris en cas de pertes de données ou de profits.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">8. Modifications des Conditions d'Utilisation</h2>
            <p className="text-gray-700 mb-4">
                Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur cette page. Il est de votre responsabilité de consulter régulièrement ces conditions pour rester informé des mises à jour.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">9. Résiliation</h2>
            <p className="text-gray-700 mb-4">
                Nous nous réservons le droit de suspendre ou de résilier votre accès à l'application à tout moment, sans préavis, en cas de violation des présentes conditions d'utilisation.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">10. Droit Applicable et Juridiction</h2>
            <p className="text-gray-700 mb-4">
                Les présentes conditions d'utilisation sont régies par les lois françaises. En cas de litige, les tribunaux français seront seuls compétents.
            </p>

            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">11. Contact</h2>
            <p className="text-gray-700 mb-4">
                Pour toute question concernant ces conditions d'utilisation, veuillez nous contacter via la page "Contact" de notre application.
            </p>
        </div>
    );
}
