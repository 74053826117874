import React from 'react';

export default function APropos() {
    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">À Propos de Monsieur Je Sais Tout</h1>
            <p className="text-gray-700 mb-4">Monsieur Je Sais Tout est une intelligence artificielle développée pour fournir des réponses rapides, précises et utiles à vos questions. Notre mission est de rendre l'information accessible à tous, gratuitement.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Notre Histoire</h2>
            <p className="text-gray-700 mb-4">Lancé en 2024, Monsieur Je Sais Tout a pour objectif de devenir le compagnon de conversation préféré des francophones, en proposant des échanges conviviaux, informatifs et divertissants.</p>
        </div>
    );
}
