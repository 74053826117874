import React from 'react';

export default function InformationsImportantes() {
    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Informations Importantes sur l'Utilisation de l'IA</h1>
            <p className="text-gray-700 mb-6">
                <strong>Attention</strong> : Les réponses fournies par l'intelligence artificielle (IA) de "Monsieur Je Sais Tout" sont générées automatiquement et peuvent contenir des erreurs, des inexactitudes ou des informations obsolètes. Bien que l'IA utilise des modèles de traitement du langage avancés, elle peut parfois produire des réponses "hallucinées," c’est-à-dire des affirmations incorrectes, fictives ou non vérifiées.
            </p>
            <p className="text-gray-700 mb-6">
                L'IA ne remplace pas un avis professionnel et ne doit pas être utilisée comme seule source d’information, en particulier dans des domaines sensibles tels que la santé, les finances, ou le droit.
            </p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Limitation de responsabilité</h2>
            <p className="text-gray-700 mb-4">
                Nous ne saurions être tenus responsables des décisions prises sur la base des réponses de l'IA. L'utilisateur est encouragé à vérifier les informations auprès de sources fiables et à faire preuve de discernement lorsqu'il interprète les réponses fournies.
            </p>
            <h3 className="text-xl font-semibold text-blue-700 mt-4 mb-2">Merci de votre compréhension</h3>
            <p className="text-gray-700">
                En utilisant ce service, vous acceptez ces limitations et reconnaissez les risques potentiels liés à l'utilisation de l'intelligence artificielle.
            </p>
        </div>
    );
}
