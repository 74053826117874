import React from 'react';

export default function PolitiqueConfidentialite() {
    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Politique de Confidentialité</h1>
            <p className="text-gray-700 mb-4">Votre vie privée est importante pour nous. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et les options que vous avez concernant ces informations.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Informations Collectées</h2>
            <p className="text-gray-700 mb-4">Nous collectons des informations lorsque vous utilisez notre site, telles que les pages visitées, les interactions avec le contenu et les informations fournies lors de l'inscription ou de l'envoi de formulaires.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Utilisation des Informations</h2>
            <p className="text-gray-700 mb-4">Les informations collectées sont utilisées pour améliorer l'expérience utilisateur, fournir des réponses adaptées et, dans certains cas, pour des activités de marketing et d'analyse.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Partage des Informations</h2>
            <p className="text-gray-700 mb-4">Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles à des tiers sans votre consentement, sauf si requis par la loi.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Cookies</h2>
            <p className="text-gray-700 mb-4">Nous utilisons des cookies pour améliorer votre expérience sur notre site. Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur.</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Contact</h2>
            <p className="text-gray-700">Pour toute question concernant cette politique de confidentialité, contactez-nous à l'adresse email suivante : contact@monsieurjesaitout.com</p>
        </div>
    );
}
