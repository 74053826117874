import React from 'react';

export default function MentionsLegales() {
    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Mentions Légales</h1>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Éditeur du Site</h2>
            <p className="text-gray-700 mb-4">Le site "Monsieur Je Sais Tout" est édité par :<br/>Nom de l'entreprise : ECALL PARTNERS<br/>Adresse : 231 Rue Saint-Honoré Paris<br/>Email : contact@monsieurjesaitout.com</p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Hébergement</h2>
            <p className="text-gray-700 mb-4">Le site est hébergé par :<br/>Nom de l'hébergeur : Amazon Web Service<br/>Adresse : Unit 27 – 6400 Cork Airport Business Park – Kinsale Road – Amazon CS Ireland Ltd est une société du groupe Amazon Web Services (AWS), dont le siège social se situe à Inc. P.O/ Box 81226 – Seattle, WA 98108-1226<br/>Téléphone :  (206) 266-4064 </p>
            <h2 className="text-2xl font-semibold text-blue-800 mt-6 mb-3">Propriété Intellectuelle</h2>
            <p className="text-gray-700 mb-4">Tous les contenus présents sur ce site sont protégés par les droits d'auteur et sont la propriété exclusive de l'éditeur. Toute reproduction, distribution ou utilisation est interdite sans autorisation.</p>
        </div>
    );
}
