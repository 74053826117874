import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            alert("Please complete the reCAPTCHA verification.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, message, recaptchaToken }),
            });

            if (response.ok) {
                alert("Message envoyé !");
                setName("");
                setEmail("");
                setMessage("");
                setRecaptchaToken(null);
            } else {
                alert("Une erreur s'est produite lors de l'envoi du message.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            alert("Une erreur s'est produite lors de l'envoi du message.");
        }
    };

    return (
        <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-blue-900 mb-4">Contactez-nous</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-gray-700">Nom</label>
                    <input type="text" className="w-full p-2 border rounded" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div>
                    <label className="block text-gray-700">Email</label>
                    <input type="email" className="w-full p-2 border rounded" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div>
                    <label className="block text-gray-700">Message</label>
                    <textarea className="w-full p-2 border rounded" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} required />
                </div>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={setRecaptchaToken}
                />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">Envoyer</button>
            </form>
        </div>
    );
}
