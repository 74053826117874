import React, { useState } from 'react';
import './App.css';
import Chat from './Chat';
import PolitiqueConfidentialite from './PolitiqueConfidentialite';
import MentionsLegales from './MentionsLegales';
import APropos from './APropos';
import Contact from './Contact';
import CommentUtiliserIA from './CommentUtiliserIA';
import InformationsImportantes from './InformationsImportantes';
import ConditionsUtilisation from './ConditionsUtilisation';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Routes, Route } from 'react-router-dom';

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <div className="flex min-h-screen flex-col">
            {/* Sticky Header Section */}
            <header className="sticky top-0 z-20 border-b border-gray-200 bg-white">
                <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center">
                        <img
                            alt="Your Company"
                            src="/vr.svg"
                            className="h-8 w-auto"
                        />
                        <h1 className="text-lg font-bold text-gray-700 ml-4 md:hidden">Monsieur Je Sais Tout</h1> {/* Visible on small screens */}
                        {/* Navigation Links */}
                        <nav className="hidden md:flex space-x-6 ml-10">
                            <a href="/" className="text-gray-600 hover:text-blue-700 text-sm font-medium">💬 Chatter avec Monsieur Je Sais Tout</a>
                            <a href="/a-propos" className="text-gray-600 hover:text-blue-700 text-sm font-medium">ℹ️ À Propos</a>
                            <a href="/comment-utiliser-intelligence-artificielle" className="text-gray-600 hover:text-blue-700 text-sm font-medium">📖 Comment Utiliser l'IA</a>
                            <a href="/informations-importantes" className="text-gray-600 hover:text-blue-700 text-sm font-medium">📌 Informations Importantes</a>
                            <a href="/contact" className="text-gray-600 hover:text-blue-700 text-sm font-medium">📨 Contact</a>
                        </nav>
                    </div>
                    {/* Drawer button for mobile view */}
                    <button
                        className="md:hidden p-2.5 text-gray-700"
                        onClick={() => setIsSidebarOpen(true)}
                    >
                        <Bars3Icon className="h-6 w-6" />
                    </button>
                </div>
            </header>

            {/* Mobile Drawer */}
            {isSidebarOpen && (
                <div className="fixed inset-0 z-30 flex items-start justify-end bg-black bg-opacity-50">
                    <div className="bg-white w-64 h-full p-4 shadow-lg">
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsSidebarOpen(false)}
                                className="text-gray-700"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <nav className="mt-4">
                            <a href="/" className="block py-2 text-gray-600 hover:text-blue-700 text-lg font-medium">💬 Chatter avec Monsieur Je Sais Tout</a>
                            <a href="/a-propos" className="block py-2 text-gray-600 hover:text-blue-700 text-lg font-medium">ℹ️ À Propos</a>
                            <a href="/comment-utiliser-intelligence-artificielle" className="block py-2 text-gray-600 hover:text-blue-700 text-lg font-medium">📖 Comment Utiliser l'IA</a>
                            <a href="/informations-importantes" className="block py-2 text-gray-600 hover:text-blue-700 text-lg font-medium">📌 Informations Importantes</a>
                            <a href="/contact" className="block py-2 text-gray-600 hover:text-blue-700 text-lg font-medium">📨 Contact</a>
                        </nav>
                    </div>
                </div>
            )}

            {/* Sticky Google Banner Ad Section positioned below the navbar */}
            <div className="sticky top-16 z-10 bg-gray-200 text-center py-2">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="h-24 md:h-32 bg-gray-300 flex items-center justify-center rounded-lg">
                        <span><i>Avant d'utiliser Monsieur Je Sais Tout, assurez-vous d'avoir consulté nos <a className="underline" href="/informations-importantes">informations importantes</a> sur l'IA, ainsi que nos <a className="underline" href="/conditions-utilisation">conditions d'utilisation</a>.</i></span>
                    </div>
                </div>
            </div>

            {/* Main Layout with Sidebars and Chat */}
            <div className="mx-auto flex w-full max-w-7xl items-start gap-x-8 sm:px-6 lg:px-8">
                {/* Left Sidebar for Advertisement or Content */}
                <aside className="sticky top-60 hidden w-44 shrink-0 lg:block">
                    <img src='/mjst-avatar.png' alt="Monsieur Je Sais Tout Avatar" />
                </aside>

                {/* Main Chat Component and Routes for other pages */}
                <main className="flex-1 p-4 bg-white rounded-lg">
                    <Routes>
                        <Route path="/" element={<Chat setShowSidebar={setIsSidebarOpen} />} />
                        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
                        <Route path="/mentions-legales" element={<MentionsLegales />} />
                        <Route path="/a-propos" element={<APropos />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/informations-importantes" element={<InformationsImportantes />} />
                        <Route path="/comment-utiliser-intelligence-artificielle" element={<CommentUtiliserIA />} />
                        <Route path="/conditions-utilisation" element={<ConditionsUtilisation />} />
                    </Routes>
                </main>

                {/* Right Sidebar for Advertisement or Content */}
                <aside className="sticky top-60 hidden w-96 shrink-0 xl:block">
                    <p className="text-gray-700 mb-4">Monsieur Je Sais Tout est une intelligence artificielle développée pour fournir des réponses rapides, précises et utiles à vos questions. Notre mission est de rendre l'information accessible à tous, gratuitement.</p>
                </aside>
            </div>

            {/* Default Footer */}
            <footer className="bg-gray-800 text-white py-4 mt-auto">
                <div className="text-center">
                    <p className="text-sm">© 2024 Monsieur Je Sais Tout - Tous droits réservés</p>
                    <div className="flex justify-center space-x-4 mt-2">
                        <a href="/politique-confidentialite" className="text-gray-300 hover:text-white text-sm">Politique de Confidentialité</a>
                        <a href="/mentions-legales" className="text-gray-300 hover:text-white text-sm">Mentions Légales</a>
                        <a href="/a-propos" className="text-gray-300 hover:text-white text-sm">À Propos</a>
                        <a href="/conditions-utilisation" className="text-gray-300 hover:text-white text-sm">Conditions d'Utilisation</a>
                        <a href="/contact" className="text-gray-300 hover:text-white text-sm">Contact</a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
